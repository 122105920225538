<template>
  <div>
    <div v-for="(groupItem, index) in tagsList" class="zidingyimemberGroup">
      <div class="topTitle clearfix" style="margin-bottom: 20px">
        <div class="left fl" style="padding: 0px">
          {{ groupItem.name }}
        </div>
      </div>
      <table>
        <template v-for="(tagsItem, tagsIndex) in groupItem.children">
          <tr>
            <td class="title" style="
                padding: 5px 0px 5px 10px;
                height: 40px;
                vertical-align: middle;
                width: 120px;
              ">
              {{ tagsItem.name }}
            </td>
            <td style="padding: 12px 0px; height: 40px; vertical-align: middle">
              <template v-if="tagsItem.tagType === '1'">
                <el-radio label="0" v-model="tagsItem.itemModel" style="margin-left: 5px">不限</el-radio>
                <template v-for="(sItem, sIndex) in tagsItem.children">
                  <el-radio :label="sItem.id" v-model="tagsItem.itemModel">{{ sItem.name }}</el-radio>
                </template>
              </template>
              <template v-if="tagsItem.tagType === '2'">
                <el-row type="flex">
                  <el-checkbox class="radio" label="0" style="margin-left: 12px; margin-right: 40px"
                    v-model="tagsItem.itemBuXianModel">不限</el-checkbox>
                  <el-checkbox-group v-model="tagsItem.itemModel">
                    <el-col v-for="sItem in tagsItem.children" :key="sItem.code" :span="2" style="min-width: 120px">
                      <el-checkbox :label="sItem.code" :disabled="!!tagsItem.itemBuXianModel" :title="sItem.name">
                        {{ sItem.name }}
                      </el-checkbox>
                    </el-col>
                  </el-checkbox-group>
                </el-row>
              </template>
              <template v-if="tagsItem.tagType === '3'">
                <el-checkbox class="radio" label="0" style="margin-left: 12px; margin-right: 20px"
                  v-model="tagsItem.itemBuXianModel">不限</el-checkbox>
                <template v-if="tagsItem.code === 'birthday_day'">
                  <el-input v-model="tagsItem.children[0].minValue" placeholder="" :controls="false"
                    style="width: 150px; margin-left: 20px" :disabled="!!tagsItem.itemBuXianModel"></el-input>
                  <em style="margin-left: 10px; margin-right: -10px">-</em>
                  <el-input v-model="tagsItem.children[0].maxValue" placeholder="" :controls="false"
                    style="width: 150px; margin-left: 20px" :disabled="!!tagsItem.itemBuXianModel"></el-input>
                  <em style="padding-left: 10px">{{ tagsItem.children[0].unit }}</em>
                  <template v-if="tagsItem.children[0].tip">
                    <span class="membertips">{{ tagsItem.children[0].tip }}</span>
                  </template>
                </template>
                <template v-else>
                  <el-input-number v-model="tagsItem.children[0].minValue" placeholder="" :controls="false"
                    style="width: 150px; margin-left: 20px" :disabled="!!tagsItem.itemBuXianModel"></el-input-number>
                  <em style="margin-left: 10px; margin-right: -10px">-</em>
                  <el-input-number v-model="tagsItem.children[0].maxValue" placeholder="" :controls="false"
                    style="width: 150px; margin-left: 20px" :disabled="!!tagsItem.itemBuXianModel"></el-input-number>
                  <em style="padding-left: 10px">{{ tagsItem.children[0].unit }}</em>
                  <template v-if="tagsItem.children[0].tip">
                    <span class="membertips">{{ tagsItem.children[0].tip }}</span>
                  </template>
                </template>
              </template>
              <template v-if="tagsItem.tagType === '4'">
                <el-checkbox class="radio" label="0" style="margin-left: 12px; margin-right: 20px"
                  v-model="tagsItem.itemBuXianModel">不限</el-checkbox>
                <template>
                  <el-input-number v-model="tagsItem.children[0].value" style="width: 150px; margin-left: 20px"
                    :controls="false" :disabled="!!tagsItem.itemBuXianModel"></el-input-number>
                  <em style="padding-left: 10px">{{ tagsItem.children[0].unit }}</em>
                  <template v-if="tagsItem.children[0].tip">
                    <span class="membertips">{{ tagsItem.children[0].tip }}</span>
                  </template>
                </template>
              </template>
              <template v-if="tagsItem.tagType === '5'">
                <el-checkbox class="radio" label="0" style="margin-left: 12px; margin-right: 40px"
                  v-model="tagsItem.itemBuXianModel">不限</el-checkbox>
                <template>
                  <el-select v-model="tagsItem.itemModel" multiple :disabled="!!tagsItem.itemBuXianModel">
                    <el-option v-for="item in tagsItem.children" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </template>
              </template>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <div style="text-align: right;border-bottom: 1px solid #f2f2f2;padding-bottom: 20px; margin: 20px 0">
      <el-button type="primary" @click="saveGroupInfor">筛选会员</el-button>
    </div>
    <div>
      共有<span style="margin: 0 20px; color: #5B69FE; font-weight: bold;">{{ externalUserid.length }}</span>会员符合筛选条件
    </div>
    <div style="text-align: center;">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'

export default {
  data () {
    return {
      externalUserid: [],
      id: '', //群组id，修改时用
      tagsList: [], //标签数据
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$emit('confirm', {externalUserid: this.externalUserid, tagsList: this.tagsList})
    },
    warningMessage (msg) {
      this.$message({
        message: msg,
        type: 'warning',
      })
    },
    computedNewData (data) {
      let detailList = cloneDeep(data)
      this.tagsList = detailList.map((item) => {
        return {
          ...item,
          children: item.children.map((elem) => {
            return {
              ...elem,
              itemModel: elem.tagType === '1' ? '0' : [],
              itemBuXianModel: true,
            }
          }),
        }
      })
    },
    filterBuXianItems (data) {
      if (data.tagType === '1') {
        return data.itemModel !== '0'
      } else {
        return !data.itemBuXianModel
      }
    },
    saveGroupInfor () {
      const userInfo = JSON.parse(localStorage.userInfo)
      let params = {
        marketCode: userInfo.marketCode,
      }
      let filterItems = []
      this.tagsList.forEach((elem) => {
        let result = elem.children.filter((item) => {
          return this.filterBuXianItems(item)
        })
        filterItems = filterItems.concat(result)
      })
      let paramsItems = []
      filterItems.forEach((item) => {
        let result = null
        if (item.tagType === '1') {
          result = {
            tagId: item.children.find((elem) => {
              return elem.id === item.itemModel
            }).id,
            value: item.children.find((elem) => {
              return elem.id === item.itemModel
            }).name,
          }
        } else if (item.tagType === '2') {
          result = item.children
            .filter((elem) => {
              return item.itemModel.includes(elem.code)
            })
            .map((child) => {
              return {
                tagId: child.id,
                value: child.name,
              }
            })
        } else if (item.tagType === '3') {
          result = {
            tagId: item.children[0].id,
            maxValue: item.children[0].maxValue,
            minValue: item.children[0].minValue,
          }
        } else if (item.tagType === '4') {
          result = {
            tagId: item.children[0].id,
            value: item.children[0].value,
          }
        } else if (item.tagType === '5') {
          result = item.children
            .filter((elem) => {
              return item.itemModel.includes(elem.id)
            })
            .map((child) => {
              return {
                tagId: child.id,
                value: child.name,
              }
            })
        }
        paramsItems = paramsItems.concat(result)
      })
      params.items = paramsItems
      if (params.items.length < 1) {
        this.warningMessage('请至少选择一项筛选条件');
        return false;
      }
      for (let i = 0; i < params.items.length; i++) {
        if ([14, 92, 62, 94, 13, 21, 20, 5, 84, 66].includes(params.items[i].tagId)) {
          if (params.items[i].minValue < 0 || params.items[i].maxValue < 0) {
            this.warningMessage('配置项不能小于0');
            return
          }
          if (params.items[i].maxValue < params.items[i].minValue) {
            this.warningMessage('最大值不能小于最小值');
            return
          }
        }
      }
      this.$api.busapi.queryGroupMember(params).then(res => {
        if (res.code == 200) {
          this.externalUserid = res.data
        }
      })
    },
    getTagGroupDetail () {
      const userInfo = JSON.parse(localStorage.userInfo)
      this.$api.busapi.getTagGroupDetail({ marketCode: userInfo.marketCode }).then(res => {
        this.computedNewData(res.data.tags)
      })
    },
  },
  created () {
    this.getTagGroupDetail()
  },

}
</script>
<style scoped>
.input {
  width: 300px;
  height: 29px;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  text-indent: 8px;
}

table td {
  text-align: left;
}

div.zidingyimemberGroup table td.title {
  width: 95px;
}

div.zidingyimemberGroup table td input.input {
  width: 90px;
  margin: 0px 5px;
}

div.zidingyimemberGroup table td span {
  color: #898989;
}

.topTitle {
  padding: 8px 0;
  border-bottom: 1px solid #f2f2f2;
  height: 30px;
  margin: 20px 0;
  font-weight: bold;
  font-size: 15px;
}
</style>
