<template>
  <div class="reportMarket" v-loading="loading">
    <template v-if="showType == 'list'">
      <d-search :searchData="searchData" @searchEvent="searchEvent" @resetEvent="resetEvent" />
      <div class="operation">
        <el-button size="small" type="primary" @click="addItem">新增</el-button>
      </div>
      <d-table :tableData='tableData' :columnData='columnData'>
        <template v-slot:member="{ row }">
          <span>{{ row.externalUserid.length }}</span>
        </template>
        <template v-slot:sendDate="{ row }">
          <span>{{ row.sendDate + ' ' + row.sendTime }}</span>
        </template>
        <template v-slot:handle="row">
          <el-link type="primary" @click="goDetail(row.item)">详情</el-link>
        </template>
      </d-table>
      <d-paging :pager="pager" @change="getItemList" />
      <el-dialog :title="dialogTitle" :visible.sync="noticeDialogVisible" :close-on-press-escape="false"
        :close-on-click-modal="false" top="5vh" width="1000px" @close="dialogClose" :before-close="handleClose">
        <div class="oticeDialogContent" v-if="!showTag">
          <div class="flex-row">
            <div class="title"><span style="color:red">*</span>筛选店铺会员：</div>
            <el-button type="text" @click="filterMember" style="margin: 0 20px;">筛选会员</el-button>
            <div>
              共有<span style="margin: 0 20px; color: #5B69FE; font-weight: bold;">{{ externalUserid.length
                }}</span>会员符合筛选条件
            </div>
          </div>
          <div class="flex-row">
            <div class="title"><span style="color:red">*</span>标题：</div>
            <el-input v-model="title" maxlength="50" placeholder="请输入标题" style="width: 350px"></el-input>
          </div>
          <div class="flex-row">
            <div class="title"><span style="color:red">*</span>公告内容：</div>
          </div>
          <div class="flex-row" style="margin-left: 120px; width: 550px;">
            <quill-editor v-model="content" ref="myQuillEditor" :options="editorOption" @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)" @change="onEditorChange($event)">
            </quill-editor>
          </div>
          <div class="flex-row">
            <div class="title">添加小程序：</div>
            <el-input v-model="page" placeholder="小程序功能路径" style="width: 350px"></el-input>
          </div>
          <div class="flex-row" style="margin-bottom:4px" v-if="page">
            <div class="title">小程序封面图：</div>
            <div>
              <div style="color:#AAAAAA;font-size:14px;margin-left: 10px;">
                最大图片不得超过5MB
              </div>
              <div style="display:flex;">
                <viewer :images="coverImage" style="display: flex;flex-wrap: wrap;">
                  <el-upload :style="{ pointerEvents: coverImage.length >= 1 ? 'none' : '' }" ref="coverUpload"
                    class="upload-demo" action="/business/upload" accept=".png,.jpg,.jpeg" :show-file-list="false"
                    :http-request="uploadCoverFile" :before-upload="beforeUpload1" :limit="limitFileNum1"
                    :on-exceed="overFile1" :multiple="true">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="images-item" v-for="(item, index) in coverImage" :key="index">
                    <img :src="item.url" :alt="item.name">
                    <div @click="removeImg1(index)" class="delete">-</div>
                  </div>
                </viewer>
              </div>
            </div>
          </div>
          <div class="flex-row" style="margin-bottom:4px">
            <div class="title">上传图片：</div>
            <div>
              <div style="color:#AAAAAA;font-size:14px;margin-left: 10px;">
                最大图片不得超过10MB，共20个文件
              </div>
              <div style="display:flex;">
                <viewer :images="images" style="display: flex;flex-wrap: wrap;">
                  <el-upload :style="{ pointerEvents: images.length >= 20 ? 'none' : '' }" ref="upload"
                    class="upload-demo" action="/business/upload" accept=".png,.jpg,.jpeg" :show-file-list="false"
                    :http-request="uploadFile" :before-upload="beforeUpload" :limit="limitFileNum" :on-exceed="overFile"
                    :multiple="true">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <div class="images-item" v-for="(item, index) in images" :key="index">
                    <img :src="item.url" :alt="item.name">
                    <div @click="removeImg(index)" class="delete">-</div>
                  </div>
                </viewer>
              </div>
            </div>
          </div>
          <div class="flex-row" style="margin-top: 20px;">
            <div class="title"><span style="color:red">*</span>发送时间：</div>
            <el-date-picker v-model="sendDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
              style="margin-right: 30px">
            </el-date-picker>
            <el-time-select v-model="sendTime" :picker-options="{ start: '09:00', step: '00:30', end: '21:00' }"
              placeholder="选择时间"></el-time-select>
          </div>
          <div class="btn">
            <el-button @click="back">取消</el-button>
            <el-button type="primary" @click="sure">保存</el-button>
          </div>
        </div>
        <tag-group v-if="showTag" @cancel="cancel" @confirm="confirm"></tag-group>
      </el-dialog>
    </template>
    <template v-if="showType == 'detail'">
      <detail :detailInfo="detailInfo" @changeType="changeType"></detail>
    </template>
  </div>
</template>

<script>

import DSearch from '@/components/d-search'
import DTable from '@/components/d-table'
import DPaging from '@/components/d-paging'
import uploadCommon from "@/utils/upload";

import {
  uploadFile
} from "@/services/sysapi";
import { Loading } from "element-ui";
import { isIE } from "@/utils/utils";
import Detail from "./detail";
import TagGroup from "./tagGroup";
// 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  data () {
    return {
      detailInfo: {},
      tagsList: [],
      coverImage: [],
      sendDate: '',
      sendTime: '',
      externalUserid: [],
      title: '',
      page: '',
      content: '',
      contentLength: 0,
      dialogTitle: '新增',
      showTag: false,
      uploadFileNum: 0,
      images: [],
      showType: 'list',
      loadingOption: {
        lock: true,
        text: '文件上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      },
      noticeDialogVisible: false,
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      loading: false,
      searchData: {
        searchItem: [{
          label: '标题名称',
          value: 'title',
          type: 'commonInput',
          dateType: 'month',
          placeholder: '请输入标题名称'
        },
        {
          label: '日期',
          value: 'date',
          type: 'commonDatetime',
          placeholder: '请选择日期'
        }
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {
          title: null,
          type: null,
          date: ['', ''],
        }
      },
      columnData: [
        { prop: 'title', label: '标题' },
        { prop: 'member', label: '筛选会员', slotProp: true },
        { prop: 'sendDate', label: '发送时间', slotProp: true },
        { prop: 'createUserName', label: '操作人' },
        { prop: 'createTime', label: '创建时间' },
        {
          prop: 'operate', label: '操作', type: {}
        }
      ],
      tableData: [],
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
            // ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
            [{ 'header': 1 }, { 'header': 2 }],
            // [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
            [{ indent: "-1" }, { indent: "+1" }],
            // [{ size: ['small', false, 'large', 'huge']}], // 配置字号
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
            [{ font: [] }], //显示字体选择
            [{ align: [] }], // 对齐方式-----
            ["clean"], // 清除文本格式-----
          ]
        },
        placeholder: "请输入内容..."
      }, //编辑器配置项
    }
  },
  computed: {
    limitFileNum () {
      return 20 - this.images.length
    },
    limitFileNum1 () {
      return 1 - this.coverImage.length
    }
  },
  components: {
    DSearch,
    DTable,
    DPaging,
    Detail,
    quillEditor,
    TagGroup
  },
  created () {
    this.init()
  },
  mounted () {

  },
  watch: {
  },
  methods: {
    init () {
      this.getItemList()
    },
    sure () {
      let params = {
        title: this.title,
        externalUserid: this.externalUserid,
        filterContent: {
          images: this.images,
          coverImage: this.coverImage,
          tagsList: this.tagsList,
        },
        sendDate: this.sendDate,
        sendTime: this.sendTime,
        msgContent: {
          text: {
            content: this.content
          },
          attachments: []
        }
      }
      if (this.images.length) {
        this.images.forEach(item => {
          params.msgContent.attachments.push({
            msgtype: 'image',
            image: {
              mediaId: item.mediaId
            }
          })
        })
      }
      if (this.page) {
        params.msgContent.attachments.push({
          msgtype: 'miniprogram',
          miniprogram: {
            title: this.title,
            page: this.page,
            picMediaId: this.coverImage[0].mediaId
          }
        })
      }

      this.$api.busapi.qyMsg(params).then(res => {
        if (res.code == 200) {
          this.noticeDialogVisible = false
          this.getItemList()
          this.$message.success('操作成功！')
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    back () {
      this.dialogClose()
    },
    addItem () {
      this.detailInfo = {}
      this.tagsList = []
      this.coverImage = []
      this.sendDate = ''
      this.sendTime = ''
      this.externalUserid = []
      this.title = ''
      this.page = ''
      this.content = ''
      this.contentLength = 0
      this.dialogTitle = '新增'
      this.showTag = false
      this.uploadFileNum = 0
      this.images = []
      this.noticeDialogVisible = true
      setTimeout(() => {
        // 设置富文本编辑器的title
        this.initTitle()
      }, 1000)
    },
    handleClose (done) {
      if (this.showTag) {
        this.dialogTitle = '新增'
        this.showTag = false
      } else {
        done()
      }
    },
    dialogClose () {
      this.noticeDialogVisible = false
    },
    goDetail (item) {
      this.detailInfo = { ...item }
      this.showType = 'detail'
    },
    filterMember () {
      this.dialogTitle = '筛选会员'
      this.showTag = true
    },
    cancel () {
      this.dialogTitle = '新增'
      this.showTag = false
    },
    confirm (e) {
      this.externalUserid = e.externalUserid
      this.tagsList = e.tagsList
      this.cancel()
    },
    onEditorBlur () {
      // 失去焦点触发事件
    },
    onEditorFocus () {
      // 获得焦点触发事件
    },
    onEditorChange (e) {
      this.contentLength = e.quill.getLength() - 1
    },
    initTitle () {
      const titleConfig = [
        { Choice: '.ql-insertMetric', title: '跳转配置' },
        { Choice: '.ql-bold', title: '加粗' },
        { Choice: '.ql-italic', title: '斜体' },
        { Choice: '.ql-underline', title: '下划线' },
        { Choice: '.ql-header', title: '段落格式' },
        { Choice: '.ql-strike', title: '删除线' },
        { Choice: '.ql-blockquote', title: '块引用' },
        { Choice: '.ql-code', title: '插入代码' },
        { Choice: '.ql-code-block', title: '插入代码段' },
        { Choice: '.ql-font', title: '字体' },
        { Choice: '.ql-size', title: '字体大小' },
        { Choice: '.ql-list[value="ordered"]', title: '编号列表' },
        { Choice: '.ql-list[value="bullet"]', title: '项目列表' },
        { Choice: '.ql-direction', title: '文本方向' },
        { Choice: '.ql-header[value="1"]', title: 'h1' },
        { Choice: '.ql-header[value="2"]', title: 'h2' },
        { Choice: '.ql-align', title: '对齐方式' },
        { Choice: '.ql-color', title: '字体颜色' },
        { Choice: '.ql-background', title: '背景颜色' },
        { Choice: '.ql-image', title: '图像' },
        { Choice: '.ql-video', title: '视频' },
        { Choice: '.ql-link', title: '添加链接' },
        { Choice: '.ql-formula', title: '插入公式' },
        { Choice: '.ql-clean', title: '清除所选格式' },
        { Choice: '.ql-script[value="sub"]', title: '下标' },
        { Choice: '.ql-script[value="super"]', title: '上标' },
        { Choice: '.ql-indent[value="-1"]', title: '向左缩进' },
        { Choice: '.ql-indent[value="+1"]', title: '向右缩进' },
        { Choice: '.ql-header .ql-picker-label', title: '标题大小' },
        { Choice: '.ql-header .ql-picker-item[data-value="1"]', title: '标题一' },
        { Choice: '.ql-header .ql-picker-item[data-value="2"]', title: '标题二' },
        { Choice: '.ql-header .ql-picker-item[data-value="3"]', title: '标题三' },
        { Choice: '.ql-header .ql-picker-item[data-value="4"]', title: '标题四' },
        { Choice: '.ql-header .ql-picker-item[data-value="5"]', title: '标题五' },
        { Choice: '.ql-header .ql-picker-item[data-value="6"]', title: '标题六' },
        { Choice: '.ql-header .ql-picker-item:last-child', title: '标准' },
        { Choice: '.ql-size .ql-picker-item[data-value="small"]', title: '小号' },
        { Choice: '.ql-size .ql-picker-item[data-value="large"]', title: '大号' },
        { Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: '超大号' },
        { Choice: '.ql-size .ql-picker-item:nth-child(2)', title: '标准' },
        { Choice: '.ql-align .ql-picker-item:first-child', title: '居左对齐' },
        { Choice: '.ql-align .ql-picker-item[data-value="center"]', title: '居中对齐' },
        { Choice: '.ql-align .ql-picker-item[data-value="right"]', title: '居右对齐' },
        { Choice: '.ql-align .ql-picker-item[data-value="justify"]', title: '两端对齐' }
      ]
      for (let item of titleConfig) {
        let tip = document.querySelector('.quill-editor ' + item.Choice)
        if (!tip) continue
        tip.setAttribute('title', item.title)
      }
    },
    changeType (val) {
      this.showType = val
    },
    removeImg (index) {
      this.images.splice(index, 1)
    },
    removeImg1 (index) {
      this.coverImage.splice(index, 1)
    },
    async uploadCoverFile (data, list) {
      const file = data.file;
      let form = new FormData;
      const img = await uploadCommon.readImg(file);
      const blob = await uploadCommon.compressImg(img, 500, 500, file.type);
      form.append("file", new window.File([blob], file.name, { type: file.type }));
      uploadFile(form, 'activity').then(async res => {
        let mediaId = await this.getPicMediaId(res.data)
        this.coverImage.push({
          title: file.name,
          name: file.name,
          url: res.data,
          size: file.size,
          mediaId
        })
        this.$refs.coverUpload.clearFiles()
      })
    },
    //覆盖原有的上传逻辑
    async uploadFile (data, list) {
      const file = data.file;
      let form = new FormData;
      const type = file.type.split('/')[1]
      let ie = isIE();
      if (['png', 'jpg', 'jpeg'].includes(type) && !ie) {
        const img = await uploadCommon.readImg(file);
        const blob = await uploadCommon.compressImg(img, 500, 500, file.type);
        form.append("file", new window.File([blob], file.name, { type: file.type }));
      } else {
        form.append("file", file)
      }
      let loading = Loading.service(this.loadingOption);
      this.uploadFileNum++;
      uploadFile(form, 'activity').then(async res => {
        this.uploadFileNum--;
        if (this.uploadFileNum == 0) {
          loading.close();
        }
        let mediaId = await this.getPicMediaId(res.data)
        this.images.push({
          title: file.name,
          name: file.name,
          url: res.data,
          size: file.size,
          mediaId
        })
        this.$refs.upload.clearFiles()
      })
    },
    getPicMediaId (url) {
      let params = {
        type: 'image',
        url
      }
      return this.$api.busapi.qyMediaUpload(params).then(res => {
        return res.data.mediaId
      })
    },
    beforeUpload (file) {
      if (this.images.length < 20) {
        if (file.type.indexOf('image') != -1) {
          file.size > 10 * 1024 * 1024 && this.$message.warning(`图片文件不能超过${10}M`)
          return !(file.size > 10 * 1024 * 1024)
        }
      } else {
        this.$message.error("最多上传20个图片");
        return false;
      }
    },
    beforeUpload1 (file) {
      if (this.coverImage.length < 1) {
        if (file.type.indexOf('image') != -1) {
          file.size > 5 * 1024 * 1024 && this.$message.warning(`图片文件不能超过${5}M`)
          return !(file.size > 5 * 1024 * 1024)
        }
      } else {
        this.$message.error("最多上传1个图片");
        return false;
      }
    },
    overFile (files, fileList) {
      if (files.length <= (20 - this.images.length)) {
        return true
      } else {
        this.$message.warning(`最多可上传20个图片,当前最多可选择 ${20 - this.images.length} 个图片`);
        this.$refs.upload.clearFiles()
        return false
      }
    },
    overFile1 (files, fileList) {
      if (files.length <= (1 - this.coverImage.length)) {
        return true
      } else {
        this.$message.warning(`最多可上传1个图片,当前最多可选择 ${1 - this.coverImage.length} 个图片`);
        this.$refs.coverUpload.clearFiles()
        return false
      }
    },
    /**
     * 搜索按钮点击
     **/
    searchEvent () {
      this.getItemList()
    },
    /**
     * 重置按钮点击
     **/
    resetEvent () {
      this.pager.pageNum = 1;
      this.pager.pageSize = 10;
      this.getItemList()
    },

    // 分页列表
    getItemList () {
      const params = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        title: this.searchData.params.title,
        beginCreateTime: (this.searchData.params.date && this.searchData.params.date[0]) || '',
        endCreateTime: (this.searchData.params.date && this.searchData.params.date[1]) || '',

      }
      this.$api.busapi.getQyMsgList(params)
        .then((res) => {
          if (res.code === 200) {
            this.pager.count = res.data.total
            this.tableData = res.data.list.map(item => ({
              ...item,
              title: (item.title || '').length > 10 ? (item.title || '').slice(0, 10) + '...' : (item.title || ''),
            }))
          }
        })
    }

  },
}
</script>
<style lang="scss" scoped>
.reportMarket {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;

  .operation {
    position: relative;
    line-height: 28px;
    display: flex;
    justify-content: flex-start;
    // margin-top: 10px;
    padding: 0 20px;
  }

  .el-table {
    flex: 1;
    margin-top: 30px;
    padding: 0 20px;
    overflow-y: auto;
  }

  .d-paging {
    margin-top: 50px;
    text-align: center;
  }

  &::v-deep .el-upload {
    display: block;
  }

  &::v-deep .el-dialog {
    // height: 88%;
    overflow-y: auto;
  }

  &::v-deep .el-dialog__body {
    max-height: 750px;
    overflow-y: auto;
  }

  .flex-row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .conten {
    flex: 1;
    border: solid 1px #D9D9D9;
    min-height: 60px;
    max-height: 140px;
    overflow: auto;
    padding: 10px;
    // display: flex;
  }

  .title {
    width: 120px;
    text-align: right;
    flex-shrink: 0;
  }

  .conten-item {
    display: inline-block;
    color: #000;
    background: #F2F2F2;
    padding: 5px 15px;
    border-radius: 20px;
    margin-bottom: 10px;
  }
}

::v-deep .imgDialog .el-dialog {
  height: 88%;
  overflow-y: auto;
}

::v-deep .imgDialog .el-dialog__body {
  max-height: 100%;
  height: 750px;
  overflow-y: auto;
}

.oticeDialogContent {
  &::v-deep .el-input__inner {
    height: 40px;
    font-size: 14px;
  }

  &::v-deep .ql-editor {
    min-height: 200px;
  }

  .upload-demo {
    width: 70px;
    height: 70px;
    line-height: 70px;
    margin-top: 5px;
    margin-left: 10px;
    text-align: center;
    background: #F2F2F2;
    // border-radius: 10px;
  }

  .images-item {
    width: 70px;
    height: 70px;
    margin-left: 10px;
    margin-top: 5px;
    position: relative;
  }

  .images-item img {
    width: 100%;
    height: 100%;
  }

  .wordfile {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);

    iframe {
      margin-left: 10%;
      margin-top: 3%;
    }
  }

  .files-item {
    margin-left: 10px;
    margin-top: 5px;
    position: relative;
  }

  .file-img {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .file-img img {
    width: 45px;
    height: 45px;
  }

  .file-img div {
    max-width: 73px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .delete {
    position: absolute;
    color: #fff;
    background: red;
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 15px;
    border-radius: 50%;
    right: -6px;
    top: -4px;
    cursor: pointer;
  }
}

.btn {
  text-align: right;
}
</style>
